import React from "react";
import {NavLink, Route, Switch} from "react-router-dom";
import {Button, Col, Container, Image, InputGroup, Nav, Row} from "react-bootstrap";
import AdvBlock from "./components/AdvBlock";
import Search from "./components/Search";
import Chat from "./components/Chat/Chat";
import {connect} from "react-redux";
import {loadDoctorData, loadSettingsData} from "./store/actions";
import BrowserCompatibilityAlert from "../../components/BrowserCompatibilityAlert";
import NotFound from "../../components/NotFound";
import SharedPatientsList from "./components/SharedPatientsList";
import DoctorPatientsList from "./components/DoctorPatientsList";
import PatientStudiesList from "./components/PatientStudiesList";

class DoctorArea extends React.Component {
    componentDidMount() {
        this.props.loadSettingsData();
        this.props.loadDoctorData();
    }

    render() {
        return (
            <Container className="app">
                <BrowserCompatibilityAlert />
                <Row>
                    <Col md={3}>
                        <Image className="logo" src="/images/logo.png" alt={process.env.REACT_APP_WEBSITE_NAME} fluid/>
                    </Col>
                    <Col md={6}>
                        <InputGroup className="search">
                            <Search />
                        </InputGroup>
                    </Col>
                    <Col md={2}>
                        <p className="mt-2">{this.props.doctor !== null ? this.props.doctor.email : "загрузка..."}</p>
                    </Col>
                    <Col md={1}>
                        <p className="text-right logout">
                            <Button variant="dark" onClick={this.props.logout}>Выйти</Button>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col md="3">
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link as={NavLink} exact activeClassName="active"
                                          to="/doctor-area/">Мои пациенты</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={NavLink} exact
                                          to="/doctor-area/patients/shared">Доступные мне</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <AdvBlock/>
                    </Col>
                    <Col md="9">
                        <Switch>
                            <Route exact path="/doctor-area/">
                                <DoctorPatientsList/>
                            </Route>
                            <Route exact
                                   path="/doctor-area/patients/shared">
                                <SharedPatientsList />
                            </Route>
                            <Route exact
                                   path="/doctor-area/patients/:patientID/studies"
                                   children={({match}) => (
                                       <PatientStudiesList patientID={match.params.patientID}/>
                                   )}>
                            </Route>
                            <Route path="/doctor-area/*">
                                <NotFound pathToHome="/doctor-area/"/>
                            </Route>
                        </Switch>
                    </Col>
                </Row>
                {this.renderChat()}
            </Container>
        )
    }

    renderChat() {
        if (this.props.chatEnabled) {
            return <Chat />
        }

        return <span />
    }
}

const mapStateToProps = (state) => {
    return {
        doctor: state.doctor,
        chatEnabled: state.settings.chatEnabled,
    }
}

const mapDispatchToProps = {
    loadSettingsData: loadSettingsData,
    loadDoctorData: loadDoctorData,
}

export default connect(mapStateToProps, mapDispatchToProps)(DoctorArea);
