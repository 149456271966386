import {applyMiddleware, createStore} from 'redux';
import {reducers} from './reducers';
import thunk from 'redux-thunk';

const initialState = {
    showAdv: false,
    advData: null,
    doctorsList: {
        doctors: [],
        currentOffset: 0,
        totalCount: 0,
    },
    patientsInWorkList: {
        patients: [],
        currentOffset: 0,
        totalCount: 0,
    },
    patientsForRestoreList: {
        patients: [],
        currentOffset: 0,
        totalCount: 0,
    },
    doctor: null,
    doctorPatientsList: {
        patients: [],
        currentOffset: 0,
        totalCount: 0,
    },
    editPatient: null,
    currentPatient: null,
    patientStudiesList: {
        studies: [],
        currentOffset: 0,
        totalCount: 0,
    },
    studySharesList: {
        shares: [],
    },
    dropdownDoctorsList: [],
    dropdownDoctorPatientsList: [],
    commonStudiesList: {
        studies: [],
        currentOffset: 0,
        totalCount: 0,
    },
    settings: {
        remoteViewerEnabled: false,
        commonStudiesListEnabled: false,
        panoProtestEnabled: false,
        chatEnabled: false,
        studyExternalShareEnabled: false,
        studyExternalShareRemoteViewerEnabled: false,
    },
    counters: {
        studiesCount: 0,
        doctorsCount: 0,
        patientsInWorkCount: 0,
        patientsInWorkRestored: 0,
    }
};

const middleware = [thunk]

const store = createStore(reducers, initialState, applyMiddleware(...middleware));

export default store;
