import {applyMiddleware, createStore} from "redux";
import {reducers} from "./reducers";
import thunk from "redux-thunk";

const initialState = {
    doctor: null,
    doctorFriends: [],
    showAdv: true,
    advData: null,
    patientsList: {
        patients: [],
        currentOffset: 0,
        totalCount: 0,
    },
    sharedPatientsList: {
        patients: [],
        currentOffset: 0,
        totalCount: 0,
    },
    currentPatient: null,
    patientStudiesList: {
        studies: [],
        currentOffset: 0,
        totalCount: 0,
    },
    patientShares: [],
    settings: {
        remoteViewerEnabled: false,
        chatEnabled: false,
    }
};

const middleware = [thunk];

const store = createStore(reducers, initialState, applyMiddleware(...middleware));

export default store;
